/* eslint-disable camelcase */
import Resource from '@guestyci/agni';
import { generateCSRFToken } from '@guestyci/agni/csrf';
import { getPermissions } from '@guestyci/ozzy/utils/api';
import { normalizeDPResponse, getIsAllowed } from '@guestyci/ozzy/utils/logic';
import t from '@guestyci/localize/t.macro';

import { utils } from 'utils';
import { datadogRum } from '../../utils/datadog';

export const FIELDS_ID = {
  email: 'okta-signin-username',
  password: 'okta-signin-password',
};

export const getNode = selector => document.querySelector(selector);

export const createChangeLink = () => {
  const linkNode = document.createElement('a');
  linkNode.textContent = t('Change');
  linkNode.href = '#';
  linkNode.classList.add('change-login-link');

  linkNode.onclick = e => {
    e.preventDefault();

    toggleEmailAndPasswordField('email', true);
    toggleEmailAndPasswordField('password', false, () =>
      toggleClassForFormFields('password', 'flex'));

    toggleElement('.error-tip-block', false);
    toggleElement('.btn-continue', true);
    removeElement('.email-block');
    toggleElement('#okta-signin-submit', false);
    toggleElement('.auth-footer', false);
  };

  return linkNode;
};

const createEmailBlock = email => {
  const container = document.createElement('div');

  const emailElement = document.createElement('p');
  const editLink = createChangeLink();

  const subTitle = getNode('.okta-form-subtitle');

  emailElement.innerHTML = email;
  emailElement.classList.add('email-text');

  container.classList.add('email-block');
  container.append(emailElement);
  container.append(editLink);

  subTitle.after(container);
};

const createAdditionalInputValueField = selector => {
  const passwordInput = getNode(selector);
  const passwordValueField = document.createElement('input');
  if (passwordInput) {
    passwordValueField.classList.add(
      'password-input-visible',
      'fs-exclude',
      'dd-privacy-mask-user-input'
    );
    passwordValueField.type = 'text';
    passwordValueField.value = passwordInput.value;

    passwordValueField.oninput = event => {
      passwordInput.value = event.target.value;
      passwordInput.dispatchEvent(new Event('input'));
    };
    passwordInput.after(passwordValueField);
  }
};

const removeAdditionalInputFieldValue = () => {
  removeElement('.password-input-visible');
};

export const addEyeIconForPasswordField = selector => {
  const passwordInput = getNode(selector);
  const passwordParentElement = passwordInput.parentElement;

  if (passwordInput && passwordParentElement) {
    const eyeIconButton = document.createElement('i');
    eyeIconButton.classList.add('eye-button-icon', 'hide');

    eyeIconButton.onclick = () => {
      if (eyeIconButton.classList.contains('hide')) {
        eyeIconButton.classList.remove('hide');
        eyeIconButton.classList.add('show');

        createAdditionalInputValueField(selector);
      } else {
        eyeIconButton.classList.remove('show');
        eyeIconButton.classList.add('hide');

        removeAdditionalInputFieldValue();
      }
    };

    passwordParentElement.appendChild(eyeIconButton);
  }
};

const toggleClassForFormFields = (type, className) => {
  const formField = getFormFieldBlock(type);

  if (formField) {
    const classes = formField.classList;

    classes.toggle(className);
  }
};

export const handleClickContinueButton = () => {
  const username = getNode('#okta-signin-username');

  const passwordFiledBlock = getFormFieldBlock('password');

  if (username && username.value !== '') {
    utils.eventsLogger.unifiedLoginDio.track('input_email', 'click');
    createEmailBlock(username?.value);
    toggleElement('.auth-footer', true);
    toggleElement('.help', false);

    toggleEmailAndPasswordField('email', false);
    toggleEmailAndPasswordField('password', true, () =>
      toggleClassForFormFields('password', 'flex'));

    toggleElement('.btn-continue', false);
    toggleElement('#okta-signin-submit', true);
  }

  if (passwordFiledBlock && passwordFiledBlock.style.display !== 'none') {
    // Remove margin-bottom for password filed
    passwordFiledBlock.classList.remove('margin-btm-30');
  }
};

export const updateTitle = text => {
  const node = getNode('.okta-form-title');
  if (node) {
    node.textContent = text;
    node.style.opacity = 100;
  }
};

export const addSubTitleText = subTitleText => {
  const container = getNode('.okta-form-title');

  const subTitle = document.createElement('p');
  subTitle.textContent = subTitleText;

  subTitle.classList.add('okta-form-subtitle');

  container.after(subTitle);
};

export const changeMFAButtonsText = mfaData => {
  return mfaData.map(item => {
    const element = findElementByText(item.tag, item.text);
    const elementToChange = element?.nextSibling?.firstElementChild;

    if (elementToChange) {
      elementToChange.innerHTML = item.buttonText;
    }

    return element;
  });
};

const findElementByText = (tag, text) => {
  return [...document.querySelectorAll(tag)].find(a =>
    a.textContent.includes(text));
};

export const addedSubTitleForSMSAuthScreen = ({
  smsSubTitleScreenMessage,
  setupSMS,
}) => {
  const smsAuthText = findElementByText('h2', setupSMS);

  if (smsAuthText) {
    const element = document.createElement('p');

    element.innerHTML = smsSubTitleScreenMessage;
    element.classList.add('okta-form-subtitle', 'mb-20');
    smsAuthText.after(element);
    return smsAuthText;
  }

  return '';
};

export const createErrorTipBlock = errorMessage => {
  const container = document.createElement('div');
  const errorTip = document.createElement('p');

  const formFieldContainer = getNode('.o-form-fieldset-container .o-form-fieldset:last-child');

  container.classList.add('error-tip-block');

  errorTip.innerHTML = errorMessage;
  errorTip.classList.add('error-tip-text');

  container.append(errorTip);
  formFieldContainer.append(container);
};

export const toggleElement = (selector, show) => {
  const element = getNode(selector);

  if (element) {
    if (show) {
      element.style.display = 'block';
    } else {
      element.style.display = 'none';
    }
  }
};

export const toggleEmailAndPasswordField = (type, show, callback = null) => {
  const formField = getFormFieldBlock(type);
  if (formField) {
    if (show) {
      formField.style.display = 'block';
    } else {
      formField.style.display = 'none';
    }
  }

  if (callback) {
    callback();
  }
};

export const getFormFieldBlock = type => {
  const formField = document.getElementById(FIELDS_ID[type])?.parentElement
    ?.parentElement?.parentElement;

  if (formField) {
    return formField;
  }

  return null;
};

export const removeElement = selector => {
  const element = getNode(selector);

  if (element) {
    element.remove();
  }
};

export const getLayoutApi = () => {
  const isHq = 'hq' === utils.getEnv().dimension;

  const { api } = Resource.create({
    domain: isHq ? 'hq-layout' : 'layout',
  });

  return api;
};

export const reportErrorToDataDog = (error, context) => {
  try {
    datadogRum.addError(error, context);
  } catch (e) {
    console.error('Failed to report error to datadog', e);
  }
};

export const reportActionToDataDog = (action, context) => {
  try {
    datadogRum.addAction(action, context);
  } catch (e) {
    console.error('Failed to report action to datadog', e);
  }
};

export const getCSRFToken = async () => {
  try {
    await generateCSRFToken({});
  } catch (e) {
    console.error('[CSRF]:', e);
  }

  return Promise.resolve({});
};

export const getSecureAuthCookie = async () => {
  const api = getLayoutApi();
  await getCSRFToken();
  await api.get('/auth');
};

const isTokenExists = () => {
  return Boolean(window.localStorage.getItem('token') ||
      window.localStorage.getItem('okta-token-storage'));
};

export const deleteSecureAuthCookie = async () => {
  const api = getLayoutApi();

  try {
    // Because we don't have access to httpOnly cookies, we can't check if the cookie exists
    // So next best thing is to try to check if the token exists in local storage
    if (isTokenExists()) {
      await api.delete('/auth');
    } else {
      console.log('No token found. Skipping delete cookie request');
    }
  } catch (e) {
    console.error(e);
    reportErrorToDataDog(e, { source: 'deleteSecureAuthCookie' });
  }
};

export function organizeLoginButtonPositions() {
  try {
    const a = document.getElementsByClassName('primary-auth-container')?.[0];
    if (!a) {
      console.log('no primary-auth-container');
      setTimeout(() => organizeLoginButtonPositions(), 150);
    } else {
      const arr = a.children;
      const divider = arr[0];
      const google = arr[1];

      if (!google) {
        reportErrorToDataDog('Error: no google element ', {
          errorType: 'okta_widget_error',
          errorLocation: 'organizeLoginButtonPositions',
        });
      }

      const email = arr[2];
      email.after(google);
      email.after(divider);
    }
  } catch (e) {
    console.error(e);
    reportErrorToDataDog(e, { source: 'organizeLoginButtonPositions' });
  }
}
// Set of predefined account IDs that should be considered as employee accounts
const EMPLOYEE_ACCOUNT_IDS = new Set([
  '596f6fe706112710005d96ff',
  '563e0b6a08a2710e00057b82',
]);

// Determine if the user is a Guesty employee based on cookies or account ID
// https://github.com/guestyorg/platform-inventory/blob/5eeb8eeb0f406d99388f3571acaee85f1e6d895a/dimensions/guesty/nginx/main%3Aproduction.conf#L465
const isGuestyEmployee = (accountId = null, email = null) => {
  const getEmployeeCookie = () => {
    const cookies = `; ${document.cookie}`;
    const parts = cookies.split('; gempl=');
    if (parts?.length === 2)
      return Boolean(parts
          .pop()
          .split(';')
          .shift());
  };
  try {
    // Check cookie, account ID and email domain
    return Boolean(getEmployeeCookie() ||
        (accountId && EMPLOYEE_ACCOUNT_IDS.has(accountId)) ||
        email?.endsWith('@guesty.com'));
  } catch {
    return false;
  }
};

export const setUserLanguage = (lang = 'en-US') => {
  window.localStorage.setItem('lang', lang);
};

export const fetchUserData = async () => {
  try {
    const { api } = Resource.create();
    const { data: user } = await api.get('/me', {
      params: {
        fields: [
          'firstName',
          'lastName',
          'fullName',
          'picture',
          '_id',
          'displayLanguage',
          'account.companyInformation',
          'email',
          'account.name',
          'account.accountCategorization',
          'account.createdAt',
          'account.active',
          'account.freezingFlow',
          'account.billing.noFreezingFlow',
        ].join(' '),
      },
    });
    return user;
  } catch (error) {
    console.log('[authpage]: failed to get user', error);
  }
};

export const setDataDogUser = async ({
  isImpersonated = false,
  isInstance = false,
  user,
}) => {
  if (!user) {
    return;
  }

  const { api: papApi } = Resource.create({ domain: '/' });
  let roles;
  try {
    const { data: rolesUnfiltered } = await papApi.get(`/pap-http/roles/${user._id}`);
    roles = rolesUnfiltered?.map(({ name, id }) => ({ name, id }));
  } catch (e) {
    console.error('[authpage] Failed to get user roles', e);
    roles = undefined;
  }

  /**
   * This is a temporary solution to determine if the user is in lite or pro package
   * As we don't have a way to get the package type from the backend, and we can't use ozzy PermissionsProvider
   * we are using the DP API to get the package type.
   */
  const ucr = {
    targets: [
      {
        action: 'navigationModes:lite',
        resource: 'routes',
      },
    ],
  };
  let packageType;
  let isGliteUser;
  try {
    const { data: permissions } = await getPermissions({
      ucr,
      userId: user._id,
    });
    const normalizedPermissions = normalizeDPResponse(permissions || {}, ucr);
    isGliteUser = getIsAllowed('navigationModes:lite', normalizedPermissions);
    packageType = isGliteUser ? 'LITE' : 'PRO';
  } catch (e) {
    console.error('[authpage] Failed to get user package type', e);
  }

  datadogRum.setUser({
    id: user._id,
    name: user.fullName,
    email: user.email,
    account_id: user.accountId,
    account_name: user.account.name,
    account_country: user.account.companyInformation?.country,
    locale: user.displayLanguage || 'en-US',
    impersonated: isImpersonated,
    instance: isInstance,
    employee: isGuestyEmployee(user.accountId, user.email),
    roles,
    package: typeof isGliteUser === 'boolean' ? packageType : undefined,
  });
};
